<template>
  <div class="home">
    <font-awesome-icon icon="tools" size="6x" />
    <HelloWorld msg="Welcome to the Janesville Makerspace!"/>
    <div class="posts">
      <ul v-if="posts && posts.length">
        <li v-for="(post,index) in posts" v-bind:key="index">
          <article>
          <h2>{{post.title}}</h2>
          <!-- <p><strong>Author:</strong>{{post.author}}</p> -->
          <p>{{post.content}}</p>
          </article>
        </li>
      </ul>
      <ul v-if="errors && errors.length">
        <li v-for="(error,index) in errors" v-bind:key="index">
          {{error.message}}
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
div.posts li {
list-style: none;
}

      
</style>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { HTTP } from '../services/http-common';
export default {
  data() {
    return{
      posts: [],
      errors: []
    }
  },
  created(){
    HTTP.get('posts')
    .then(response => {
      this.posts = response.data
    })
    .catch(e=> {
      this.errors.push(e)
    })
  },
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
