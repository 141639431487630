<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <h3>
      <p>
        Janesville, Wisconsin is the home to workers and makers.  The aim of the Janesville Makerspace is to give those makers the means to build and make for themselves.  
        It's a place for learning new skills, trying out cool tools and techniques, and mastering crafts.  It's a place for artists to create in a large community studio, and 
        for residents to repair their own things, or build new ones.
      </p>
    </h3>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
